<template>
  <CContainer>
    <div class="d-flex justify-content-between align-center mx-3 my-4">
      <h1>Available Icons</h1>
      <CSelect v-model="color" :options="colors" class="w-25 mr-4" size="sm">
        <template #default="{option}">
          {{ option.label }}
        </template>
      </CSelect>
    </div>

    <div class="d-flex flex-wrap mb-5">
      <div v-for="icon in icons" :key="icon">
        <div class="i-cont">
          <div class="i-icon">
            <CIcon :name="icon" size="custom-size" :class="[color]" :width="24" :height="24" class="m-1"/>
          </div>

          <div class="i-footer">
            <div>{{ icon }}</div>
          </div>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import {cipIcons} from "@/assets/icons/cipIcons";

export default {
  name: 'IconsList',
  data: () => ({
    color: '',
  }),
  computed: {
    icons() {
      return Object.keys(cipIcons).map(key => {
        return key
      }).sort()
    },
    colors() {
      return [
        {value: '', label: 'Select color...'},
        {value: 'text-primary', label: 'Primary'},
        {value: 'text-secondary', label: 'Secondary'},
        {value: 'text-success', label: 'Success'},
        {value: 'text-info', label: 'Info'},
        {value: 'text-warning', label: 'Warning'},
        {value: 'text-danger', label: 'Danger'},
      ]
    }
  }


}
</script>
<style scoped>
.i-cont {
  margin: 6px;
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.i-icon {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-footer {
  padding: 8px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ccc;
  font-size: 12px;
  margin-top: 5px;
  overflow: auto;
}
</style>